import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { getParams } from "@/services/api/utils";

const EXPORT_URI = Settings.API_URI_EXPORT;

/**
 * Get the export on contact level from the API
 * @param {string} contact_id ID of the contact
 **/
export const getContactExport = (contact_id) => {
  let config = {
    method: "get",
    url: `${EXPORT_URI}/contact`,
    params: getParams({ contact_id: contact_id }),
  };

  return Interceptor(config);
};

/**
 * Get the export of all contacts of a project from the API
 * @param {string} contact_id ID of the contact
 **/
export const getAllContactsExport = (project_id) => {
  let config = {
    method: "get",
    url: `${EXPORT_URI}/contacts`,
    params: getParams({ project_id: project_id }),
  };

  return Interceptor(config);
};

/**
 * Get the export on project level from the API
 * @param {string} project_id ID of the project
 **/
export const getProjectExport = (project_id) => {
  let config = {
    method: "get",
    url: `${EXPORT_URI}/project`,
    params: getParams({ project_id: project_id }),
  };

  return Interceptor(config);
};
