<template>
  <div>
    <iq-empty-state
      v-if="notes.length === 0"
      icon="sticky_note_2"
      title="Keine Notizen gefunden"
    />

    <div class="note" v-else v-for="note in notes" :key="note.id">
      <div class="note--title">
        <md-icon style="color: var(--iq-gray-400)">sticky_note_2</md-icon>
        <span>{{ note.title }}</span>

        <md-button
          class="md-icon-button delete-button md-accent"
          @click="deleteNote(note.id)"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="left">Notiz löschen</md-tooltip>
        </md-button>
      </div>

      <div class="note--body">{{ note.description }}</div>

      <div class="note--footer">
        Erstellt am: {{ new Date(note.created_at).toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
import { deleteNote } from "@/services/api/contact";

export default {
  name: "contactNotes",
  props: {
    notes: {
      type: Array,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.notes = this.notes.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  },
  methods: {
    handleDelete(e) {
      this.$emit("delete", e);
    },
    async deleteNote(id) {
      try {
        let response = await deleteNote(id, this.contactId);
        this.handleDelete({
          type: "success",
          message: "Notiz wurde erfolgreich gelöscht",
          payload: response,
        });
      } catch (error) {
        this.handleDelete({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.note {
  background-color: white;
  border-radius: $border-radius;
  border: $border-default;
  margin-bottom: 1rem;

  &:hover > .note--title > .delete-button {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: initial;
  }

  &--title {
    padding: $padding $padding 0 $padding;
    font-size: 16px;
    color: var(--iq-gray-400);
    position: relative;

    & > span {
      margin-left: 0.5rem;
    }

    & > .delete-button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      margin: 0;
      opacity: 0;
    }
  }

  &--body {
    padding: $padding;
    color: #636363;
    white-space: pre-wrap;
  }

  &--footer {
    padding: 0 $padding $padding $padding;
    color: var(--iq-gray-400);
  }
}

.notes-skeleton {
  height: 150px;
  border-radius: $border-radius;
  background-color: var(--iq-gray-100);
  margin-bottom: 1rem;
  position: relative;

  & > div {
    position: relative;
    width: 150px;
    height: 20px;
    top: 1rem;
    left: 1rem;
    background-color: var(--iq-gray-200);

    &:nth-child(2) {
      width: 350px;
      margin-bottom: 0.5rem;
    }

    &:nth-child(3) {
      width: 550px;
    }
  }

  &--title {
    margin-bottom: 1rem;
  }

  &--footer {
    margin-top: 1rem;
  }

  @include skeletonAnimation();
}

@include skeletonKeyframes();
</style>
