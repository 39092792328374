<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Neues Attribut hinzufügen</md-dialog-title>

    <md-dialog-content style="min-width: 750px">
      <md-field md-clearable>
        <md-icon>data_object</md-icon>
        <label>Name des Attributs</label>
        <md-input maxlength="30" required v-model="attribute_key" />
        <span class="md-helper-text"
          >Bitte geben Sie hier den Namen des Attributs an</span
        >
      </md-field>

      <md-field>
        <md-icon style="margin-right: 12px">data_object</md-icon>
        <label>Typ des Attributs</label>
        <md-select required v-model="attribute_type">
          <md-option value="string">Text</md-option>
          <md-option value="int">Zahl</md-option>
          <md-option value="choice">Auswahl</md-option>
        </md-select>
        <span class="md-helper-text"
          >Bitte geben Sie hier den Typ des Attributs an</span
        >
      </md-field>

      <md-field md-clearable v-if="['string', 'int'].includes(attribute_type)">
        <md-icon>data_object</md-icon>
        <label>Wert des Attributs</label>
        <md-input maxlength="40" required v-model="attribute_value" />
        <span class="md-helper-text"
          >Bitte geben Sie hier den Wert des Attributs an</span
        >
      </md-field>

      <md-field v-else>
        <md-icon>data_object</md-icon>
        <label>Wert des Attributs</label>
        <md-select v-model="attribute_value" style="margin-left: 10px">
          <md-option value="yes">Ja</md-option>
          <md-option value="no">Nein</md-option>
        </md-select>
        <span class="md-helper-text">Bitte geben Sie hier den Wert des Attributs an</span>
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="createAttribute"
        >Speichern</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";
import { createContactAttribute } from "@/services/api/contact";

export default {
  name: "contactMetaDataCreateDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      attribute_key: null,
      attribute_value: null,
      attribute_type: "string",
    };
  },
  methods: {
    closeDialog(e) {
      this.$emit("closeDialog", e);
      this.reset();
    },
    async createAttribute() {
      try {
        let response = await createContactAttribute(this.contactId, this.$data);
        let res = {
          payload: response.data,
          type: "success",
          message: "Attribut wurde erfolgreich erstellt!",
        };
        this.closeDialog(res);
      } catch (error) {
        console.error(error);
        let res = {
          type: "error",
          message: "Attribut konnte nicht erstellt werden.",
        };
        this.closeDialog(res);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
