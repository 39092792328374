<template>
  <div class="contact-survey-wrapper">
    <iq-loader :loading="loading" />

    <div class="survey-wrapper" v-if="!loading && survey">
      <div class="md-title">{{ survey.name }}</div>
      <div class="md-caption">{{ survey.description }}</div>

      <survey-renderer
        :fields="fields"
        :submission="submission"
        @submitSurvey="handleSubmitSubmission"
        @saveSurvey="handleSaveSubmission"
      />
    </div>

    <iq-empty-state
      title="Kein Fragebogen für dieses Projekt"
      icon="rule"
      v-else
    />

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
      >{{ errorMessage }}</md-snackbar
    >
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
      >{{ successMessage }}</md-snackbar
    >
  </div>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import { getSurvey, getSurveyFields } from "@/services/api/survey";
import SurveyRenderer from "@/components/survey/surveyRenderer";
import { SurveyEngine } from "@/services/survey/SurveyEngine";

export default {
  name: "contactSurvey",
  components: { SurveyRenderer },
  mixins: [loadingMixin, errorMixin, successMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
    },
    submissions: {
      type: Array,
    },
  },
  data() {
    return {
      survey: {},
      fields: [],
      submission: {},
      isSendButtonEnabled: false,
    };
  },
  created() {
    this.getSurvey();
  },
  methods: {
    async getSurvey() {
      try {
        this.survey = await getSurvey(this.projectId);
        if (this.survey) {
          this.fields = await getSurveyFields(this.survey.id);
          if (this.submissions && this.submissions.length) {
            const subsOne = SurveyEngine.getSubmissionFromFields(this.fields);
            const subTwo = this.submissions.reduce(
              (acc, field) => ({
                ...acc,
                [field.field_key]: field,
              }),
              {}
            );
            this.submission = { ...subsOne, ...subTwo };
          } else {
            this.submission = SurveyEngine.getSubmissionFromFields(this.fields);
          }
        }
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    checkSubmissionForEmptyAnswer(data) {
      for (let key in data) {
        if (data[key].field_type !== "multiple_choice") {
          if (!data[key].field_answer && data[key].field_required) {
            this.toggleError("Ein oder mehrere Felder wurden nicht ausgefüllt");
            return false;
          }
        } else {
          if (
            data[key].field_multi_answer.length === 0 &&
            data[key].field_required
          ) {
            this.toggleError("Ein oder mehrere Felder wurden nicht ausgefüllt");
            return false;
          }
        }
      }
      this.isSendButtonEnabled = true;
      return true;
    },
    // Wenn der 'Speichern-Button' gedrückt wird
    handleSaveSubmission(data) {
      let submissionList = [];
      for (let key in data) {
        submissionList.push(data[key]);
      }
      this.emitSaveSubmission(submissionList);
    },
    emitSaveSubmission(data) {
      let params = {
        data: data,
        survey_id: this.survey.id,
      };
      this.$emit("saveSurvey", params);
    },
    
    //Wenn der 'Absenden-Button' gedrückt wird
    handleSubmitSubmission(data) {
      if (!this.checkSubmissionForEmptyAnswer(data)) return 0;
      let submissionList = [];
      for (let key in data) {
        submissionList.push(data[key]);
      }
      this.emitSubmitSubmission(submissionList);
    },
    emitSubmitSubmission(data) {
      let params = {
        data: data,
        survey_id: this.survey.id,
      };
      this.$emit("submitSurvey", params);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/main";

.contact-survey-wrapper {
  position: relative;
}

.survey-wrapper {
  padding: $padding;
  border: $border-default;
  border-radius: $border-radius;
  background-color: white;
}
</style>
