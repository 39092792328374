<template>
  <iq-main>
    <iq-loader :loading="loading" />

    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <template v-slot:left>
          <md-button
            class="md-primary md-icon-button"
            style="margin: 0 0.5rem 0 0"
            @click="
              redirect({ name: 'Project Contacts', params: { id: projectId } })
            "
          >
            <md-icon>arrow_back</md-icon>
            <md-tooltip md-direction="right">Zurück zur Übersicht</md-tooltip>
          </md-button>

          <div class="md-title">
            {{ getFullName }}
            <span style="opacity: 0.5">
              {{
                contact.joint_practice
                  ? `(Gemeinschaftspraxis ${contact.joint_practice})`
                  : ""
              }}
            </span>
          </div>
          <iq-status-pill
            :status="contact.status"
            :emit-click="true"
            class="margin-left-1 status-pill-adjuster"
            @click="toggleDialog('statusUpdate')"
          />
        </template>

        <template v-slot:right>
          <md-button
            class="margin-right-1 md-primary md-icon-button"
            @click="toggleDialog('lastContact')"
          >
            <md-icon>today</md-icon>
            <md-tooltip md-direction="left"
              >Erster und letzter Kontakt</md-tooltip
            >
          </md-button>

          <md-menu md-align-trigger class="margin-right-1">
            <md-button class="md-primary md-icon-button" md-menu-trigger>
              <md-icon>add_circle_outline</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item @click="toggleDialog('noteCreate')">
                <md-icon>sticky_note_2</md-icon>
                <span class="md-list-item-text">Notiz erstellen</span>
              </md-menu-item>

              <md-menu-item @click="toggleDialog('taskCreate')">
                <md-icon>task_alt</md-icon>
                <span class="md-list-item-text">Aufgabe erstellen</span>
              </md-menu-item>

              <md-menu-item @click="toggleDialog('metaDataCreate')">
                <md-icon>data_object</md-icon>
                <span class="md-list-item-text">Attribut erstellen</span>
              </md-menu-item>
            </md-menu-content>
          </md-menu>

          <md-button
            class="margin-right-1 md-primary md-icon-button"
            @click="handleExport"
          >
            <md-icon>cloud_download</md-icon>
            <md-tooltip md-direction="left">Daten herunterladen</md-tooltip>
          </md-button>

          <md-menu md-align-trigger style="width: max-content">
            <md-button
              class="margin-right-1 md-primary md-icon-button"
              md-menu-trigger
            >
              <md-icon>manage_accounts</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item @click="toggleDialog('statusUpdate')">
                <md-icon>update</md-icon>
                <span class="md-list-item-text">Status ändern</span>
              </md-menu-item>

              <md-menu-item
                @click="toggleDialog('contactUpdate')"
                v-if="isComponentActive(['admin'])"
              >
                <md-icon>edit</md-icon>
                <span class="md-list-item-text">Kontakt bearbeiten</span>
              </md-menu-item>

              <md-divider />

              <md-menu-item @click="removeJointPracticeFromContact">
                <span class="md-list-item-text" style="color: #ff5252"
                  >Gemeinschaftspraxis löschen</span
                >
              </md-menu-item>

              <md-divider v-if="isComponentActive(['admin'])" />

              <md-menu-item
                @click="toggleDialog('delete')"
                v-if="isComponentActive(['admin'])"
              >
                <md-icon class="md-accent">delete</md-icon>
                <span class="md-list-item-text" style="color: #ff5252"
                  >Kontakt löschen</span
                >
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </template>
      </iq-toolbar>

      <!-- Content -->
      <div class="tri-wrapper">
        <div class="tri-wrapper--left">
          <contact-details-list :contact="contact" :users="users" />

          <contact-meta-data-list
            :attributes="attributes"
            @editAttribute="attributeUpdateHandler"
          />
        </div>

        <div class="tri-wrapper--center">
          <div class="iq-tabs">
            <div
              class="iq-tabs--item"
              :class="active === 'questionnaire' ? 'iq-tabs--item--active' : ''"
              @click="setActive('questionnaire')"
              v-if="submissions.length === 0"
            >
              Fragebogen
            </div>

            <div
              class="iq-tabs--item"
              :class="active === 'questionnaire' ? 'iq-tabs--item--active' : ''"
              @click="setActive('questionnaire')"
              v-else
            >
              Antworten
            </div>

            <div
              class="iq-tabs--item"
              :class="active === 'tasks' ? 'iq-tabs--item--active' : ''"
              @click="setActive('tasks')"
            >
              <span
                class="iq-tabs--item--counter"
                :class="
                  openTasksCount === 0
                    ? 'iq-tabs--item--counter--inactive'
                    : 'iq-tabs--item--counter--active'
                "
              >
                {{ openTasksCount }}
              </span>
              Aufgaben
            </div>
          </div>

          <div class="iq-tabs--content">
            <contact-task-list
              :tasks="tasks"
              :contact-id="contactId"
              v-if="active === 'tasks'"
              @updatedTaskList="setTasksCount"
            />

            <contact-survey
              :project-id="projectId"
              @submitSurvey="surveySubmitHandler"
              @saveSurvey="surveySaveHandler"
              :submissions="submissions"
              v-if="active === 'questionnaire'"
            />
          </div>
        </div>

        <div class="tri-wrapper--right">
          <div class="iq-subheader iq-m-b-1-75">Notizen</div>

          <contact-notes
            :notes="notes"
            :contact-id="contactId"
            @delete="notesDeleteHandler"
          />
        </div>
      </div>
    </div>

    <!-- Note create dialog -->
    <contact-note-create-dialog
      :show-dialog="dialogs.noteCreate"
      :contact-id="contactId"
      @closeDialog="closeDialogHandler('noteCreate', 'notes', $event)"
    />

    <!-- Status update dialog -->
    <contact-status-dialog
      :currentStatus="contact.status"
      :contact-id="contactId"
      :show-dialog="dialogs.statusUpdate"
      @closeDialog="closeDialogHandler('statusUpdate', 'contact', $event)"
    />

    <!-- Contact update dialog -->
    <contact-update-dialog
      :contact-id="contactId"
      :show-dialog="dialogs.contactUpdate"
      @closeDialog="closeDialogHandler('contactUpdate', 'contact', $event)"
    />

    <!-- Task create dialog -->
    <contact-task-create-dialog
      :contact-id="contactId"
      :show-dialog="dialogs.taskCreate"
      @closeDialog="closeDialogHandler('taskCreate', 'tasks', $event)"
    />

    <!-- Attribute data create dialog -->
    <contact-meta-data-create-dialog
      :contact-id="contactId"
      :is-active="dialogs.metaDataCreate"
      @closeDialog="closeDialogHandler('metaDataCreate', 'attribute', $event)"
    />

    <!-- Attribute edit dialog -->
    <contact-attribute-edit-dialog
      :is-active="dialogs.attributeEdit"
      :selected-attribute="selectedAttribute"
      @closeDialog="toggleDialog('attributeEdit')"
      @success="
        (e) => {
          this.attributes = e.data;
          this.toggleSnackbar('success', e.msg);
        }
      "
      @error="toggleSnackbar('error', $event)"
    />

    <!-- Contact delete dialog -->
    <md-dialog :md-active.sync="dialogs.delete">
      <md-dialog-title>Kontakt löschen</md-dialog-title>
      <md-dialog-content>
        <p>Sind Sie sich sicher, dass Sie diesen Kontakt löschen möchten?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="toggleDialog('delete')">Abbrechen</md-button>
        <md-button class="md-accent" @click="deleteContact"
          >Kontakt löschen</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <!-- Contact last contact dialog -->
    <md-dialog :md-active.sync="dialogs.lastContact">
      <md-dialog-title>Erster und letzter Kontakt</md-dialog-title>
      <md-dialog-content style="min-width: 500px; overflow: hidden">
        <div>Erster Kontakt</div>
        <md-field>
          <md-input type="date" v-model="contact.first_contact" />
        </md-field>
        <div>Letzter Kontakt</div>
        <md-field>
          <md-input type="date" v-model="contact.last_contact" />
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="toggleDialog('lastContact')">Abbrechen</md-button>
        <md-button class="md-primary" @click="updateLastContact"
          >Kontakt aktualisieren</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <!-- Snackbars -->
    <md-snackbar
      :md-active.sync="snackbars.success"
      class="snackbar-adjuster snackbar-adjuster--success"
    >
      <span>{{ snackbars.successMessage }}</span>
    </md-snackbar>

    <md-snackbar
      :md-active.sync="snackbars.error"
      class="snackbar-adjuster snackbar-adjuster--error"
    >
      <span>{{ snackbars.errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      :md-active.sync="isSuccess"
      class="snackbar-adjuster snackbar-adjuster--success"
    >
      {{ successMessage }}
    </md-snackbar>

    <md-snackbar
      :md-active.sync="isError"
      class="snackbar-adjuster snackbar-adjuster--error"
    >
      {{ errorMessage }}
    </md-snackbar>
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import ContactDetailsList from "@/components/contact/contactDetailsList";
import routingMixin from "@/mixins/routingMixin";
import ContactNotes from "@/components/contact/contactNotes";
import ContactNoteCreateDialog from "@/components/contact/contactNoteCreateDialog";
import {
  getContact,
  deleteContact,
  getNotes,
  getTasks,
  getContactSubmissions,
  updateContact,
  getContactAttributes,
  removeJointPractice,
} from "@/services/api/contact";
import { getContactExport } from "@/services/api/export";
import ConvertToCsv, { convertObjectToCsv } from "@/services/export";
import ContactStatusDialog from "@/components/contact/contactStatusDialog";
import ContactUpdateDialog from "@/components/contact/contactUpdateDialog";
import ContactTaskList from "@/components/contact/contactTaskList";
import ContactTaskCreateDialog from "@/components/contact/contactTaskCreateDialog";
import accessControlMixin from "@/mixins/accessControlMixin";
import {
  createBlocking,
  deleteBlocking,
  getBlocking,
} from "@/services/api/blocking";
import { getUser } from "@/services/api/user";
import ContactSurvey from "@/components/contact/contactSurvey";
import {
  createSurveySubmissions,
  updateSurveySubmission,
} from "@/services/api/submission";
import { getProjectUsers } from "@/services/api/project";
import ContactMetaDataList from "@/components/contact/contactMetaDataList";
import ContactMetaDataCreateDialog from "@/components/contact/contactMetaDataCreateDialog";
import ContactAttributeEditDialog from "@/components/contact/contactAttributeEditDialog";
import successMixin from "@/mixins/successMixin";
import errorMixin from "@/mixins/errorMixin";

export default {
  name: "Contact",
  components: {
    ContactAttributeEditDialog,
    ContactMetaDataCreateDialog,
    ContactMetaDataList,
    ContactSurvey,
    ContactTaskCreateDialog,
    ContactTaskList,
    ContactUpdateDialog,
    ContactStatusDialog,
    ContactNoteCreateDialog,
    ContactNotes,
    ContactDetailsList,
  },
  mixins: [
    loadingMixin,
    routingMixin,
    successMixin,
    errorMixin,
    accessControlMixin,
  ],
  data() {
    return {
      interval: null,
      active: "questionnaire",
      projectId: null,
      contactId: null,
      contact: null,
      submissions: [],
      notes: [],
      tasks: [],
      openTasksCount: 0,
      attributes: [],
      user: null,
      users: null,
      dialogs: {
        noteCreate: false,
        statusUpdate: false,
        contactUpdate: false,
        taskCreate: false,
        metaDataCreate: false,
        attributeEdit: false,
        delete: false,
        lastContact: false,
      },
      snackbars: {
        success: false,
        successMessage: null,
        error: false,
        errorMessage: null,
      },
      selectedAttribute: {
        attribute_key: null,
        attribute_value: null,
        attribute_type: null,
      },
    };
  },
  computed: {
    getFullName() {
      let fullName = `${this.contact.first_name} ${this.contact.last_name}`;
      if (this.contact.title) fullName = `${this.contact.title} ${fullName}`;
      return fullName;
    },
  },
  created() {
    this.projectId = this.$route.params.id;
    this.contactId = this.$route.params.contact_id;
    this.getContact();
  },
  async beforeMount() {
    let response = await getBlocking(this.contactId);
    let user = await getUser();
    if (response.data.is_blocked && response.data.user_id !== user.id) {
      await this.redirect({ name: "Project", params: { id: this.projectId } });
    }
    if (!response.data.is_blocked) {
      await this.redirect({ name: "Project", params: { id: this.projectId } });
    }
  },
  mounted() {
    this.interval = setInterval(async () => {
      await createBlocking(this.contactId);
    }, 60000);
  },
  async beforeDestroy() {
    clearInterval(this.interval);
    let response = await getBlocking(this.contactId);
    let user = await getUser();
    if (response.data.is_blocked && response.data.user_id !== user.id) {
      await this.redirect({ name: "Project", params: { id: this.projectId } });
    } else {
      await deleteBlocking(this.contactId);
    }
  },
  methods: {
    setActive(id) {
      this.active = id;
    },
    async getContact() {
      try {
        this.contact = await getContact(this.contactId);
        this.notes = await getNotes(this.contactId);
        this.tasks = await getTasks(this.contactId);
        this.openTasksCount = this.getTasksCount(this.tasks);
        let submissions = await getContactSubmissions(this.contactId);
        this.submissions = submissions.data;
        let attributes = await getContactAttributes(this.contactId);
        this.attributes = attributes.data;
        this.user = await getUser();
        this.users = await getProjectUsers(this.projectId);
        this.toggleLoading();
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    async deleteContact() {
      try {
        await deleteContact(this.contactId);
        await this.redirect({
          name: "Project Contacts",
          params: { id: this.projectId },
        });
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    getTasksCount(tasks) {
      return tasks.filter((task) => task.is_completed === false).length;
    },
    setTasksCount(count) {
      this.openTasksCount = count;
    },
    toggleDialog(id) {
      this.dialogs[id] = !this.dialogs[id];
    },
    closeDialogHandler(id, resource, e) {
      this.toggleDialog(id);
      this.snackbars[e.type + "Message"] = e.message;
      this.snackbars[e.type] = true;
      if (e.type === "success" && resource === "notes")
        this.notes.push(e.payload);
      if (e.type === "success" && resource === "contact")
        this.contact = e.payload;
      if (e.type === "success" && resource === "tasks")
        this.tasks.push(e.payload);
      if (e.type === "success" && resource === "attribute")
        this.attributes = e.payload;
    },
    notesDeleteHandler(e) {
      this.notes = e.payload;
      this.snackbars[e.type + "Message"] = e.message;
      this.snackbars[e.type] = true;
    },
    attributeUpdateHandler(attribute) {
      this.selectedAttribute = attribute;
      this.toggleDialog("attributeEdit");
    },
    async updateLastContact() {
      try {
        await updateContact(this.contactId, {
          last_contact:
            new Date(this.contact.last_contact).toISOString() ?? null,
          first_contact:
            new Date(this.contact.first_contact).toISOString() ?? null,
        });
        this.snackbars.successMessage =
          "Kontakt wurde erfolgreich aktualisiert!";
        this.snackbars.success = true;
        this.toggleDialog("lastContact");
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    async removeJointPracticeFromContact() {
      try {
        await removeJointPractice(this.contactId);
        this.contact.joint_practice = null;

        this.snackbars.successMessage =
          "Kontakt wurde von Gemeinschaftspraxis entfernt";
        this.snackbars.success = true;
      } catch (e) {
        console.error(e);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    async surveySaveHandler(args){
      try {
        const submissionsForCreate = args.data.filter((i) => !i.id);
        const submissionForUpdate = args.data.filter((i) => i.id);

        if (submissionsForCreate.length) {
          let response = await createSurveySubmissions(
            this.contactId,
            args.survey_id,
            this.projectId,
            submissionsForCreate
          );
          this.submissions = response.data;
          this.snackbars.successMessage =
            "Antworten wurden erfolgreich gespeichert!";
          this.snackbars.success = true;
        }

        if (submissionForUpdate.length) {
          for (const sub of submissionForUpdate) {
            let response = await updateSurveySubmission(sub.id, {
              field_answer: sub.field_answer,
              field_multi_answer: sub.field_multi_answer,
            });
            this.submissions = [...this.submissions, response];
            this.snackbars.successMessage =
              "Antworten wurden erfolgreich aktualisiert!";
            this.snackbars.success = true;
          }
        }

        this.contact = await updateContact(this.contactId, {
          status: "in_progress",
          edited_by: `${this.user.first_name} ${this.user.last_name}`,
        });

        location.reload();
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    async surveySubmitHandler(args) {
      try {
        const submissionsForCreate = args.data.filter((i) => !i.id);
        const submissionForUpdate = args.data.filter((i) => i.id);

        if (submissionsForCreate.length) {
          let response = await createSurveySubmissions(
            this.contactId,
            args.survey_id,
            this.projectId,
            submissionsForCreate
          );
          this.submissions = response.data;
          this.snackbars.successMessage =
            "Antworten wurden erfolgreich gespeichert!";
          this.snackbars.success = true;
        }

        if (submissionForUpdate.length) {
          for (const sub of submissionForUpdate) {
            let response = await updateSurveySubmission(sub.id, {
              field_answer: sub.field_answer,
              field_multi_answer: sub.field_multi_answer,
            });
            this.submissions = [...this.submissions, response];
            this.snackbars.successMessage =
              "Antworten wurden erfolgreich aktualisiert!";
            this.snackbars.success = true;
          }
        }

        this.contact = await updateContact(this.contactId, {
          status: "in_review",
          edited_by: `${this.user.first_name} ${this.user.last_name}`,
        });

        location.reload();
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
    toggleSnackbar(type, msg) {
      type === "error" && this.toggleError(msg);
      type === "success" && this.toggleSuccess(msg);
    },
    createDownloadLink(csvUri) {
      let el = document.createElement("a");
      el.download = `${this.contact.first_name}_${this.contact.last_name}_export.csv`;
      el.href = csvUri;
      el.target = "_blank";
      el.click();
    },
    async handleExport() {
      try {
        let response = await getContactExport(this.contactId);
        let converter = new ConvertToCsv(convertObjectToCsv, response.data);
        let csvUri = await converter.convert();
        this.createDownloadLink(csvUri);
      } catch (error) {
        console.error(error);
        this.snackbars.errorMessage =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
        this.snackbars.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.md-datepicker {
  margin: 0 1rem 0 0 !important;
}

.note {
  background-color: white;
  border-radius: $border-radius;
  border: $border-default;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: initial;
  }

  &--title {
    padding: $padding $padding 0 $padding;
    font-size: 16px;
    color: var(--iq-gray-400);

    & > span {
      margin-left: 0.5rem;
    }
  }

  &--body {
    padding: $padding;
    color: #636363;
  }

  &--footer {
    padding: 0 $padding $padding $padding;
    color: var(--iq-gray-300);
  }
}

.iq-tabs {
  display: flex;
  margin-bottom: 1rem;

  &--item {
    margin-right: 2rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 20px;
    font-weight: 500;
    color: var(--iq-gray-400);
    border-bottom: 3px solid transparent;
    cursor: pointer;
    transition: $transition;
    position: relative;

    &:hover {
      border-bottom: 3px solid var(--iq-blue-100);
      color: var(--iq-blue-500);
    }

    &--active {
      border-bottom: 3px solid var(--iq-blue-500);
      color: var(--iq-blue-500);
    }

    &--counter {
      position: absolute;
      top: -10px;
      right: -20px;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;

      &--active {
        background-color: var(--iq-red-500);
      }

      &--inactive {
        background-color: var(--iq-red-300);
      }
    }
  }
}

.status-pill-adjuster:hover {
  cursor: pointer;
}
</style>
