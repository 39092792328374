import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { getParams, cleanPayload } from "@/services/api/utils";
import "./types";

const CONTACT_URI = Settings.API_URI_CONTACT;

/**
 * Get contact from API - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @return { Promise<ContactObject> }
 * @throws { APIError }
 **/
const getContact = (contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${CONTACT_URI}/`,
      params: getParams({ contact_id: contact_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } project_id ID of the project
 * @param { ContactCreateObject } data Payload
 * @return { Promise<ContactObject> }
 * @throws { APIError }
 **/
const createContact = (project_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${CONTACT_URI}/`,
      params: getParams({ project_id: project_id }),
      data: data,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @param { !ContactUpdateObject | object } data Payload
 * @return { Promise<ContactObject> }
 * @throws { APIError }
 **/
const updateContact = (contact_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${CONTACT_URI}/`,
      params: getParams({ contact_id: contact_id }),
      data: data,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @param { !ContactUpdateObject | object } data Payload
 * @return { Promise<ContactObject> }
 * @throws { APIError }
 **/
const removeJointPractice = (contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${CONTACT_URI}/`,
      params: getParams({ contact_id: contact_id }),
      data: { joint_practice: "" },
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const deleteContact = (contact_id) => {
  let config = {
    method: "delete",
    url: `${CONTACT_URI}/`,
    params: getParams({ contact_id: contact_id }),
  };

  return Interceptor(config);
};

/**
 * Bulk upload contacts
 * @param {string} project_id
 * @param {ContactCreateObject} data
 **/
const bulkUploadContacts = (project_id, data) => {
  let config = {
    method: "post",
    url: `${CONTACT_URI}/bulk`,
    params: getParams({ project_id: project_id }),
    data: data,
  };

  return Interceptor(config);
};

/**
 * Get all notes of a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @return { Promise<NoteObject[]> }
 * @throws { APIError }
 **/
const getNotes = (contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${CONTACT_URI}/notes`,
      params: getParams({ contact_id: contact_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new note for a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @param { !NoteCreateObject } data Payload
 * @return { Promise<NoteObject[]> }
 * @throws { APIError }
 **/
const createNote = (contact_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${CONTACT_URI}/notes`,
      params: getParams({ contact_id: contact_id }),
      data: data,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Delete a note of a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } note_id ID of the note
 * @param { !String } contact_id ID of the contact
 * @return { Promise<NoteObject[]> }
 * @throws { APIError }
 **/
const deleteNote = (note_id, contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: `${CONTACT_URI}/notes`,
      params: getParams({ note_id: note_id, contact_id: contact_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get all tasks of a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @return { Promise<TaskObject[]> }
 * @throws { APIError }
 **/
const getTasks = (contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${CONTACT_URI}/tasks`,
      params: getParams({ contact_id: contact_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new task for a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } contact_id ID of the contact
 * @param { !TaskCreateObject } data Payload
 * @return { Promise<TaskObject> }
 * @throws { APIError }
 **/
const createTask = (contact_id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${CONTACT_URI}/tasks`,
      params: getParams({ contact_id: contact_id }),
      data: data,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update a task of a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { String } task_id ID of the contact
 * @param { Boolean } is_completed
 * @return { Promise<TaskObject> }
 * @throws { APIError }
 **/
const updateTask = (task_id, is_completed) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${CONTACT_URI}/tasks`,
      params: getParams({ task_id: task_id }),
      data: {
        is_completed: is_completed,
      },
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Delete a task of a contact - More information: {@link http://localhost:8000/docs API-Docs}
 * @param { !String } task_id ID of the task
 * @param { !String } contact_id ID of the contact
 * @return { Promise<TaskObject[]> }
 * @throws { APIError }
 **/
const deleteTask = (task_id, contact_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: `${CONTACT_URI}/tasks`,
      params: getParams({ task_id: task_id, contact_id: contact_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get all submission items of a contact
 * @param {string} contact_id ID of the contact
 **/
const getContactSubmissions = (contact_id) => {
  let config = {
    method: "get",
    url: `${CONTACT_URI}/submissions`,
    params: getParams({ contact_id: contact_id }),
  };

  return Interceptor(config);
};

/**
 * Get all attributes of a contact
 * @param {string} contact_id ID of the contact
 **/
const getContactAttributes = (contact_id) => {
  let config = {
    method: "get",
    url: `${CONTACT_URI}/attributes`,
    params: getParams({ contact_id: contact_id }),
  };

  return Interceptor(config);
};

/**
 * Get all attributes of a contact
 * @param {string} contact_id ID of the contact
 * @param {AttributeCreateObject} data Payload
 **/
const createContactAttribute = (contact_id, data) => {
  let config = {
    method: "post",
    url: `${CONTACT_URI}/attributes`,
    params: getParams({ contact_id: contact_id }),
    data: data,
  };

  return Interceptor(config);
};

const createContactAttributeBulk = (project_id, data) => {
  return Interceptor({
    method: "post",
    url: `${CONTACT_URI}/attributes/bulk`,
    params: getParams({ project_id }),
    data: data,
  });
};

/**
 * Update an attribute
 * @param {string} attribute_id ID of the attribute
 * @param {Object} data Payload
 **/
const updateContactAttribute = (attribute_id, data) => {
  let config = {
    method: "patch",
    url: `${CONTACT_URI}/attributes`,
    params: getParams({ attribute_id: attribute_id }),
    data: cleanPayload(data),
  };

  return Interceptor(config);
};

/**
 * Delete an attribute
 * @param {string} attribute_id ID of the attribute
 **/
const deleteContactAttribute = (attribute_id) => {
  let config = {
    method: "delete",
    url: `${CONTACT_URI}/attributes`,
    params: getParams({ attribute_id: attribute_id }),
  };

  return Interceptor(config);
};

export {
  getContact,
  createContact,
  updateContact,
  deleteContact,
  getNotes,
  createNote,
  deleteNote,
  getTasks,
  createTask,
  updateTask,
  deleteTask,
  bulkUploadContacts,
  getContactSubmissions,
  getContactAttributes,
  createContactAttribute,
  updateContactAttribute,
  deleteContactAttribute,
  removeJointPractice,
  createContactAttributeBulk,
};
