<template>
  <div>
    <!-- Dialog -->
    <md-dialog :md-active="showDialog">
      <md-dialog-title>Neue Notiz erstellen</md-dialog-title>

      <md-dialog-content style="width: 750px">
        <md-field>
          <label>Titel</label>
          <md-input type="text" v-model="title" />
        </md-field>

        <md-field>
          <label>Beschreibung</label>
          <md-textarea v-model="description" />
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
        <md-button class="md-primary" @click="createNote"
          >Notiz erstellen</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { createNote } from "@/services/api/contact";

export default {
  name: "contactNoteCreateDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: null,
      description: null,
    };
  },
  methods: {
    closeDialog(e) {
      this.$emit("closeDialog", e);
      this.resetData();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    async createNote() {
      let data = {
        title: this.title,
        description: this.description,
      };

      try {
        let response = await createNote(this.contactId, data);
        this.closeDialog({
          type: "success",
          message: "Notiz wurde erfolgreich erstellt",
          payload: response,
        });
      } catch (error) {
        this.closeDialog({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
