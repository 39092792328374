/**
 * Convert an object to a csv data uri
 * @param {object} data The data to convert
 * @returns {string} Data uri
 **/
export const convertObjectToCsv = (data) => {
  if (typeof data !== "object") throw new Error("Data must be an object");

  let header = [];
  let row = [];

  let csvUri = "data:text/csv;charset=utf-8,";

  for (let key in data) {
    // Check if key already exists in header and add data to row
    header = [...header, '"' + key + '"'];
    row = [...row, '"' + data[key] + '"'];
  }

  return csvUri.concat(
    encodeURIComponent(header.join(",") + "\n"),
    encodeURIComponent(row.join(",").replace(",,", ",Keine Angabe,") + "\n")
  );
};

/**
 * Convert an array of objects to a csv data uri
 * @param {object[]} data The data to convert
 * @returns {string} Data uri
 **/
export const convertArrayToCsv = (data) => {
  let header = [];
  let body = [];

  let csvUri = "data:text/csv;charset=utf-8,";

  // Add all headers and data
  for (let item of data) {
    let row = [];
    for (let key in item) {
      // Check if key already in header and add data to row
      if (!header.includes('"' + key + '"'))
        header = [...header, '"' + key + '"'];
      row = [...row, '"' + item[key] + '"'];
    }
    body = [...body, row];
  }

  // Add the header to the csvUri
  csvUri = csvUri.concat(encodeURIComponent(header.join(",") + "\n"));

  // Convert all body rows to strings and add to the csvUri
  for (let row of body) {
    csvUri = csvUri.concat(
      encodeURIComponent(row.join(",").replace(",,", ",Keine Angabe,") + "\n")
    );
  }

  return csvUri;
};

export default class ConvertToCsv {
  /**
   * Convert to CSV using a strategy
   * @param {function} strategy The strategy to use for converting
   * @param {object || object[]} data Data to convert
   **/
  constructor(strategy, data) {
    this._strategy = strategy;
    this._data = data;
  }

  /**
   * Uses the strategy from the constructor and converts the data to a csv data uri
   * @returns {Promise<string>}
   **/
  convert() {
    return new Promise((resolve, reject) => {
      if (this._strategy) resolve(this._strategy(this._data));
      else reject("Invalid strategy");
    });
  }
}
