<template>
  <md-dialog :md-active="showDialog">
    <md-dialog-title>Neue Aufgabe erstellen</md-dialog-title>

    <md-dialog-content style="width: 750px">
      <md-field>
        <label>Aufgabe</label>
        <md-textarea v-model="title"  md-autogrow></md-textarea>
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="close">Abbrechen</md-button>
      <md-button class="md-primary" @click="create"
        >Aufgabe erstellen</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { createTask } from "@/services/api/contact";

export default {
  name: "contactTaskCreateDialog",
  props: {
    contactId: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    close(e) {
      this.$emit("closeDialog", e);
      this.reset();
    },
    async create() {
      try {
        let response = await createTask(this.contactId, this.$data);
        this.close({
          type: "success",
          message: "Aufgabe wurde erfolgreich erstellt",
          payload: response,
        });
      } catch (error) {
        console.error(error);
        this.close({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
