import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import "./types";
import { getParams } from "@/services/api/utils";

const BLOCKING_URI = Settings.API_URI_BLOCKING;

/**
 * Check if a contact is blocked
 * @param {string} id ID of the contact
 * @return {Promise}
 * @throws {APIError}
 **/
const getBlocking = (id) => {
  let config = {
    method: "get",
    url: `${BLOCKING_URI}/`,
    params: getParams({ contact_id: id }),
  };

  return Interceptor(config);
};

const createBlocking = (id) => {
  let config = {
    method: "post",
    url: `${BLOCKING_URI}/`,
    params: getParams({ contact_id: id }),
  };

  return Interceptor(config);
};

const deleteBlocking = (id) => {
  let config = {
    method: "delete",
    url: `${BLOCKING_URI}/`,
    params: getParams({ contact_id: id }),
  };

  Interceptor(config);
};

export { getBlocking, createBlocking, deleteBlocking };
