<template>
  <div>
    <div class="iq-subheader">Allgemeine Informationen</div>

    <iq-list-new>
      <iq-list-item-new title="Referenz-ID">
        {{ contact.ref_id | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Anrede">
        {{ contact.salutation | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Title">
        {{ contact.title | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Vorname">
        {{ contact.first_name | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Nachname">
        {{ contact.last_name | emptyValue }}
      </iq-list-item-new>

      <!-- <iq-list-item-new title="Vor- und Nachname">
        {{ contact.first_name }} {{ contact.last_name }}
      </iq-list-item-new> -->
      
      <iq-list-item-new title="Straße und Hausnummer">
        {{ contact.address | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Postleitzahl">
        {{ contact.postal_code | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Ort">
        {{ contact.city | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Geographie">
        {{ contact.geography | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Region">
        {{ contact.region | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Telefonnummer">
        {{ contact.phone_number | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="OneKey-ID">
        {{ contact.one_key_id | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Erster Kontakt">
        {{ contact.first_contact | formatDate }}
      </iq-list-item-new>

      <iq-list-item-new title="Letzter Kontakt">
        {{ contact.last_contact | formatDate }}
      </iq-list-item-new>

      <iq-list-item-new title="Typ">
        <span v-if="contact.is_doctor">Doktor</span>
        <span v-else>Fachangestellter</span>
      </iq-list-item-new>

      <iq-list-item-new title="Fachgruppe">
        {{ contact.professional_group | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Fachrichtung">
        {{ contact.speciality | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Segment">
        {{ contact.segment | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Institution">
        {{ contact.institution | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="E-Mail-Adresse">
        {{ contact.mail | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Faxnummer">
        {{ contact.fax_number | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Website-URL">
        {{ contact.website_url | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Zuordnung">
        {{ formatLabel(contact.label) }}
      </iq-list-item-new>

      <iq-list-item-new title="Bearbeitet von">
        {{ contact.edited_by | emptyValue }}
      </iq-list-item-new>

      <iq-list-item-new title="Außenmitarbeiter">
        {{ contact.field_worker | emptyValue }}
      </iq-list-item-new>
    </iq-list-new>
  </div>
</template>

<script>
export default {
  name: "contactDetailsList",
  props: {
    users: {
      type: Array,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  filters: {
    emptyValue(value) {
      if (value) {
        return value;
      } else {
        return "Keine Angabe";
      }
    },
    formatDate(value) {
      if (!value) return "Keine Angabe";
      const parsed = new Date(Date.parse(value));
      return new Intl.DateTimeFormat("de-DE").format(parsed);
    },
  },
  methods: {
    formatLabel(value) {
      if (!this.users && !this.users.length) {
        return value;
      }

      const user = this.users.find((user) => user.id === value);

      if (!user) {
        return value;
      }

      return `${user.first_name} ${user.last_name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.md-list {
  background-color: var(--iq-gray-50);
}
</style>
