<template>
  <md-dialog :md-active="showDialog">
    <md-dialog-title>Kontakt aktualisieren</md-dialog-title>

    <md-dialog-content style="width: 750px">
      <div class="md-caption">
        Hier können Sie einen Kontakt aktualisieren. Dazu müssen Sie nur die
        Felder ausfüllen, welche Sie ändern möchten.
      </div>

      <md-field>
        <label>OneKey-ID</label>
        <md-input v-model="one_key_id" />
      </md-field>

      <md-field>
        <label>Referenz-ID</label>
        <md-input v-model="ref_id" />
      </md-field>

      <md-field>
        <label>Anrede</label>
        <md-input v-model="salutation" />
      </md-field>

      <md-field>
        <label>Title</label>
        <md-input v-model="title" />
      </md-field>

      <md-field>
        <label>Vorname</label>
        <md-input v-model="first_name" />
      </md-field>

      <md-field>
        <label>Nachname</label>
        <md-input v-model="last_name" />
      </md-field>

      <md-field>
        <label>Fachgruppe</label>
        <md-input v-model="professional_group" />
      </md-field>

      <md-field>
        <label>Fachrichtung</label>
        <md-input v-model="speciality" />
      </md-field>

      <md-field>
        <label>Institution</label>
        <md-input v-model="institution" />
      </md-field>

      <md-field>
        <label>Gemeinschaftspraxis</label>
        <md-input v-model="joint_practice" />
      </md-field>

      <md-field>
        <label>Telefonnummer</label>
        <md-input v-model="phone_number" />
      </md-field>

      <md-field>
        <label>E-Mail-Adresse</label>
        <md-input v-model="mail" />
      </md-field>

      <md-field>
        <label>Faxnummer</label>
        <md-input v-model="fax_number" />
      </md-field>

      <md-field>
        <label>Website-URL</label>
        <md-input v-model="website_url" />
      </md-field>

      <md-field>
        <label>Straße und Hausnummer</label>
        <md-input v-model="address" />
      </md-field>

      <md-field>
        <label>Ort</label>
        <md-input v-model="city" />
      </md-field>

      <md-field>
        <label>Postleitzahl</label>
        <md-input v-model="postal_code" />
      </md-field>

      <md-field>
        <label>Geographie</label>
        <md-input v-model="geography" />
      </md-field>

      <md-field>
        <label>Region</label>
        <md-input v-model="region" />
      </md-field>

      <md-field>
        <label>Segment</label>
        <md-input v-model="segment" />
      </md-field>

      <md-field>
        <label>Zuordnung</label>
        <md-input v-model="label" />
      </md-field>

      <md-field>
        <label>Bearbeitet von</label>
        <md-input v-model="edited_by" />
      </md-field>

      <md-field>
        <label>Außenmitarbeiter</label>
        <md-input v-model="field_worker" />
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="update"
        >Kontakt aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { updateContact, getContact } from "@/services/api/contact";

export default {
  name: "contactUpdateDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      salutation: null,
      title: null,
      first_name: null,
      last_name: null,
      ref_id: null,
      phone_number: null,
      mail: null,
      fax_number: null,
      label: null,
      one_key_id: null,
      address: null,
      city: null,
      geography: null,
      region: null,
      postal_code: null,
      website_url: null,
      speciality: null,
      professional_group: null,
      institution: null,
      segment: null,
      edited_by: null,
      joint_practice: null,
      field_worker: null,
    };
  },
  created() {
    this.projectId = this.$route.params.id;
    this.contactId = this.$route.params.contact_id;
    this.getContactData();
  },
  methods: {
    async getContactData() {
      try {
        this.contact = await getContact(this.contactId);
        Object.assign(this.$data, this.contact);
      } catch (error) {
        this.closeDialog({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten.",
        });
      }
    },
    closeDialog(e) {
      this.$emit("closeDialog", e);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    getData() {
      let data = {};
      for (let key in this.$data) {
        if (this.$data[key]) data[key] = this.$data[key];
      }
      return data;
    },
    async update() {
      let data = this.getData();

      try {
        let response = await updateContact(this.contactId, data);
        this.closeDialog({
          type: "success",
          message: "Kontakt wurde erfolgreich aktualisiert",
          payload: response,
        });
      } catch (error) {
        this.closeDialog({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten.",
        });
      }

      this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
