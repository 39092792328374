<template>
  <md-dialog :md-active="showDialog">
    <md-dialog-title>Status aktualisieren</md-dialog-title>

    <md-dialog-content style="width: 500px">
      <md-field>
        <label>Status</label>
        <md-select v-model="status">
          <md-option value="backlog">Nicht bearbeitet</md-option>
          <md-option value="on_hold">Pausiert</md-option>
          <md-option value="in_progress">In Bearbeitung</md-option>
          <md-option value="retired">Ausgeschieden</md-option>
          <md-option value="finished" v-if="isComponentActive(['admin'])"
            >Abgeschlossen</md-option
          >
        </md-select>
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="update"
        >Status aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { updateContact } from "@/services/api/contact";
import accessControlMixin from "@/mixins/accessControlMixin";

export default {
  name: "contactStatusDialog",
  mixins: [accessControlMixin],
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: this.currentStatus === "in_review" ? "finished" : "in_progress",
    };
  },
  methods: {
    closeDialog(e) {
      this.$emit("closeDialog", e);
      this.resetData();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    async update() {
      let data = {
        status: this.status,
      };

      try {
        let response = await updateContact(this.contactId, data);
        this.closeDialog({
          type: "success",
          message: "Status wurde erfolgreich aktualisiert",
          payload: response,
        });
      } catch (error) {
        this.closeDialog({
          type: "error",
          message: "Leider ist ein Fehler aufgetreten.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
