<template>
  <div>
    <iq-empty-state
      icon="task_alt"
      title="Keine Aufgaben gefunden"
      v-if="tasks.length === 0"
    />

    <md-list class="wrapper" v-else>
      <md-list-item
        v-for="(task, index) in tasks"
        :key="task.id"
        @dblclick="void 0"
      >
        <md-checkbox
          v-model="task.is_completed"
          @change="update(task.id, index)"
        />
        <p class="md-list-item-text" style="white-space: pre-line;">{{ task.title }}</p>
        <span class="created-date"
          >Erstellt am:
          {{ new Date(task.created_at).toLocaleDateString() }}</span
        >
      </md-list-item>
    </md-list>

    <!-- snackbar -->
    <md-snackbar
      class="snackbar-adjuster"
      :class="snackbar.type"
      :md-active.sync="snackbar.show"
    >
      <span>{{ snackbar.message }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { updateTask } from "@/services/api/contact";

export default {
  name: "contactTaskList",
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snackbar: {
        show: false,
        type: "snackbar-adjuster--success",
        message: null,
      },
    };
  },
  created() {
    this.tasks = this.tasks.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
  },
  methods: {
    toggleSnackbar(success) {
      if (success) {
        this.snackbar.type = "snackbar-adjuster--success";
        this.snackbar.message = "Aufgabe wurde erfolgreich aktualisiert!";
      } else {
        this.snackbar.type = "snackbar-adjuster--error";
        this.snackbar.message =
          "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!";
      }
      this.snackbar.show = true;
    },
    async update(id, index) {
      try {
        await updateTask(id, this.tasks[index].is_completed);
        this.toggleSnackbar(1);
        this.publishUpdate();
      } catch (error) {
        console.error(error);
        this.toggleSnackbar();
      }
    },
    publishUpdate() {
      let count = this.tasks.filter(
        (task) => task.is_completed === false
      ).length;
      this.$emit("updatedTaskList", count);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";

.wrapper {
  border: $border-default;
  border-radius: $border-radius;
  background-color: white;
}

.md-list {
  width: 100%;
}

.md-divider:last-child {
  display: none;
}

.created-date {
  color: var(--iq-gray-400);
  font-size: 14px;
  letter-spacing: 0.01em;
}
</style>
