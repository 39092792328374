<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>
      Attribut bearbeiten
      <md-button class="md-accent" @click="deleteAttribute">
        Attribut löschen
      </md-button>
    </md-dialog-title>

    <md-dialog-content>
      <md-field md-clearable>
        <md-icon>data_object</md-icon>
        <label>Name des Attributs</label>
        <md-input
          maxlength="30"
          required
          v-model="selectedAttribute.attribute_key"
        />
        <span class="md-helper-text"
          >Bitte geben Sie hier den Namen des Attributs an</span
        >
      </md-field>

      <md-field md-clearable>
        <md-icon>data_object</md-icon>
        <label>Wert des Attributs</label>
        <md-input
          maxlength="40"
          required
          v-model="selectedAttribute.attribute_value"
        />
        <span class="md-helper-text"
          >Bitte geben Sie hier den Wert des Attributs an</span
        >
      </md-field>

      <md-field>
        <md-icon style="margin-right: 12px">data_object</md-icon>
        <label>Typ des Attributs</label>
        <md-select required v-model="selectedAttribute.attribute_type">
          <md-option value="string">Text</md-option>
          <md-option value="int">Zahl</md-option>
        </md-select>
        <span class="md-helper-text"
          >Bitte geben Sie hier den Wert des Attributs an</span
        >
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="updateAttribute"
        >Aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";
import {
  updateContactAttribute,
  deleteContactAttribute,
} from "@/services/api/contact";

export default {
  name: "contactAttributeEditDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    selectedAttribute: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attribute: null,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
      this.reset();
    },
    emitError() {
      this.$emit(
        "error",
        "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal!"
      );
    },
    emitSuccess(data, msg) {
      this.$emit("success", { data: data, msg: msg });
    },
    async updateAttribute() {
      try {
        let response = await updateContactAttribute(
          this.selectedAttribute.id,
          this.selectedAttribute
        );
        this.closeDialog();
        this.emitSuccess(
          response.data,
          "Attribut wurde erfolgreich aktualisiert!"
        );
      } catch (error) {
        console.error(error);
        this.closeDialog();
        this.emitError();
      }
    },
    async deleteAttribute() {
      try {
        let response = await deleteContactAttribute(this.selectedAttribute.id);
        this.closeDialog();
        this.emitSuccess(response.data, "Attribut wurde erfolgreich gelöscht!");
      } catch (error) {
        console.error(error);
        this.closeDialog();
        this.emitError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-title {
  position: relative;
  margin-bottom: initial;
  padding: 24px;

  & > .md-button {
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
  }
}

.md-dialog-content {
  width: 750px;
}
</style>
