<template>
  <div style="margin-top: 16px" v-if="attributes.length > 0">
    <div class="iq-subheader">Attribute</div>

    <md-list>
      <div v-for="attribute in attributes" :key="attribute.id">
        <md-list-item @click="editAttribute(attribute)">
          <md-icon>data_object</md-icon>
          <span class="md-list-item-text">{{ attribute.attribute_key }}</span>
          <span>{{ attribute.attribute_value | resolveAttributeValue }}</span>
        </md-list-item>

        <md-divider class="md-inset" />
      </div>
    </md-list>
  </div>
</template>

<script>
export default {
  name: "contactMetaDataList",
  props: {
    attributes: {
      type: Array,
      required: true,
    },
  },
  filters: {
    resolveAttributeValue(value) {
      if (value === 'yes') return 'Ja';
      if (value === 'no') return 'Nein';
      return value;
    }
  },
  methods: {
    editAttribute(attribute) {
      this.$emit("editAttribute", attribute);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-list {
  background-color: var(--iq-gray-50);
}
</style>
