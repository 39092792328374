import Interceptor from "@/services/Interceptor";
import { getParams } from "@/services/api/utils";
import "./types";
import Settings from "@/config";

const SUBMISSION_URI = Settings.API_URI_SUBMISSION;

/**
 * Prepare the submission to be sent to the API
 * @param {object} data Payload
 * @return {FormFieldSubmissionObject[]}
 **/
const preparePayload = (data) => {
  let payload = [];
  for (let key in data) {
    if (data[key].field_answer === null) data[key].field_answer = "";
    payload.push(data[key]);
  }
  return payload;
};

/**
 * Submit answers of a contact
 * @param {string} contact_id ID of the contact
 * @param {string} survey_id ID of the survey
 * @param {string} project_id ID of the project
 * @param {object} data Payload
 **/
const createSurveySubmissions = (contact_id, survey_id, project_id, data) => {
  let config = {
    method: "post",
    url: `${SUBMISSION_URI}/`,
    params: getParams({
      contact_id: contact_id,
      survey_id: survey_id,
      project_id: project_id,
    }),
    data: preparePayload(data),
  };

  return Interceptor(config);
};

/**
 * Update an answer
 * @param {string} submission_id
 * @param {object} data
 **/
const updateSurveySubmission = (submission_id, data) => {
  let config = {
    method: "patch",
    url: `${SUBMISSION_URI}/`,
    params: getParams({ submission_id: submission_id }),
    data: data,
  };

  return Interceptor(config);
};

export { createSurveySubmissions, updateSurveySubmission };
